import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Facebook from "../../Assets/images/icons/facebook.webp";
import Instagram from "../../Assets/images/icons/instagram.webp";
import Linkedin from "../../Assets/images/icons/linkedin.webp";
import Youtube from "../../Assets/images/icons/youtube.webp";
import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import Context from "../../Context";

import "./index.scss";
const Header = () => {
    const { resumo } = useContext(Context);
    const [menu, setMenu] = useState(false);
    return (
        <div id="header">
            <div className="container">
                <Link to="/" title="Guindu">
                    {resumo.imagens && <img src={resumo.imagens.logo} alt="Guindu" />}
                </Link>
                <button type="button" className={`toggleMenu ${menu ? "active" : ""}`} onClick={() => setMenu(!menu)}>
                    {!menu && <img src={Menu} alt="Menu" title="Menu" />}
                    {menu && <img src={Close} alt="Menu" title="Menu" />}
                </button>
                <div className={`menu ${menu ? "active" : ""}`} >
                    <Link to="/" title="Home">Home</Link>
                    <Link to="/empresa" title="Empresa">Empresa</Link>
                    <Link to="/equipamentos" title="Equipamentos">Equipamentos</Link>
                    <Link to="/contato" title="Contato">Contato</Link>
                </div>
                <div className="socials">
                    {resumo.configuracoes &&
                        <>
                            {resumo.configuracoes.facebook.length > 5 &&
                                <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>
                            }
                            {resumo.configuracoes.instagram.length > 5 &&
                                <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>
                            }
                            {resumo.configuracoes.linkedin.length > 5 &&
                                <a href={resumo.configuracoes.linkedin} target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin" /></a>
                            }
                            {resumo.configuracoes.youtube.length > 5 &&
                                <a href={resumo.configuracoes.youtube} target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="Youtube" /></a>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header;