import "./index.scss";
import { useContext } from "react";
import Input from "../../Components/Form/Input";
import Facebook from "../../Assets/images/icons/facebook.webp";
import Instagram from "../../Assets/images/icons/instagram.webp";
import Linkedin from "../../Assets/images/icons/linkedin.webp";
import Youtube from "../../Assets/images/icons/youtube.webp";
import Context from "../../Context";
import { Link } from "react-router-dom";
import Form from "../Form";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <div className="newsletter">
                <div className="container">
                    <div className="content">
                        <h3>Cadastre-se e fique por dentro de todas as novidades.</h3>
                        <Form>
                            <input type="hidden" name="formulario" value="newsletter" />
                            {resumo.formularios.newsletter.campos.map(campo =>
                                <Input size={campo.tamanho} label={campo.label} name={campo.slug} type={campo.tipo} required={campo.obrigatorio} opcoes={campo.opcoes} key={`newsletter-${campo.slug}`} />
                            )}
                            <div className="submit">
                                <div className="check">
                                    <input type="checkbox" name="lgpd" id="lgpd" value="1" required />
                                    <label htmlFor="lgpd">
                                        <span className="checkbox"></span> <span>Li e estou de acordo com os <Link to="/termos-de-uso" title="Termos de uso">termos de uso</Link> e <Link to="/politica-de-privacidade" title="Política de privacidade">política de privacidade</Link>.</span>
                                    </label>
                                </div>
                                <button type="submit">Cadastre-se</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="menu">
                <div className="container">
                    <Link to="/" title="Guindu">
                        {resumo.imagens && <img src={resumo.imagens.logo_footer} alt="Guindu" />}
                    </Link>
                    <div className="menu">
                        <Link to="/" title="Home">Home</Link>
                        <Link to="/empresa" title="Empresa">Empresa</Link>
                        <Link to="/equipamentos" title="Equipamentos">Equipamentos</Link>
                        <Link to="/contato" title="Contato">Contato</Link>
                    </div>
                    <div className="socials">
                        {resumo.configuracoes &&
                            <>
                                {resumo.configuracoes.facebook.length > 5 &&
                                    <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>
                                }
                                {resumo.configuracoes.instagram.length > 5 &&
                                    <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>
                                }
                                {resumo.configuracoes.linkedin.length > 5 &&
                                    <a href={resumo.configuracoes.linkedin} target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin" /></a>
                                }
                                {resumo.configuracoes.youtube.length > 5 &&
                                    <a href={resumo.configuracoes.youtube} target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="Youtube" /></a>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="endereco">
                <div className="container">
                    {resumo.configuracoes && <p>Telefone: {resumo.configuracoes.telefone} | {resumo.configuracoes.whatsapp} |  {resumo.configuracoes.endereco} - {resumo.configuracoes.bairro} - {resumo.configuracoes.cidade}, {resumo.configuracoes.uf} - {resumo.configuracoes.cep}</p>}
                </div>
            </div>
            <div className="creditos">
                <div className="container">
                    <p>Desenvolvido por <a href="https://fw2propaganda.com.br/" target="_blank" rel="noopener noreferrer">FW2 Propaganda</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer;